import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { getFundConfig } from '@flock/shared-ui'
import { getTenant } from '../components/utils'

export const Head = () => {
  const fundName = getFundConfig().companyName
  const description = `Swap your home for shares of the ${fundName} 721 Fund to defer taxes, receive distributions, and maintain appreciation potential.`
  return (
    <>
      <title>{fundName} | 721 Fund</title>
      <meta
        title="title"
        property="og:title"
        content={`${fundName} | 721 Fund`}
      />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta name="robots" content="noindex" />
    </>
  )
}

const Home = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tenant = getTenant()
      navigate(`/onboarding?tenant=${tenant}`)
    }
  }, [])
  return <div />
}

export default Home
